// @import "node_modules/bootstrap-sass/assets/stylesheets/bootstrap";
$icon-font-path: "/fonts/bootstrap/";
$fa-font-path:   "../../../fonts/fontawesome/";

@import '_bootstrap';

@import "font-awesome";
@import url(https://fonts.googleapis.com/css?family=Teko:400,300&subset=latin,latin-ext);

@import "fancybox";

.popover {
    &.confirmation {
        width: 165px !important;
    }
}
@mixin no-padding($side) {
    @if $side == 'all' {
        .no-padding {
            padding: 0 !important;
        }
    } @else {
        .no-padding-#{$side} {
            padding-#{$side}: 0 !important;
        }
    }
}

@include no-padding("left");
@include no-padding("right");
@include no-padding("top");
@include no-padding("bottom");
@include no-padding("all");

@mixin no-margin($side) {
    @if $side == 'all' {
        .no-margin {
            margin: 0 !important;
        }
    } @else {
        .no-margin-#{$side} {
            margin-#{$side}: 0 !important;
        }
    }
}

@include no-margin("left");
@include no-margin("right");
@include no-margin("top");
@include no-margin("bottom");
@include no-margin("all");

.js-reset-form a.refresh {
    content: '';
    background: url(../../../assets/images/icons/refresh.svg) no-repeat center center;
    width: 40px;
    height: 35px;
    float: right;
}

.node-icon-ce{
    margin-right: 6px;
    opacity: .2;
    filter: alpha(opacity=20);
    cursor: pointer;
}

.node-icon-ce.icon {
    position: relative;
    top: 1px;
    display: inline-block;
    font-family: 'Glyphicons Halflings';
    font-style: normal;
    font-weight: 400;
    line-height: 1;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}
.node-icon-ce:before {
    content: "\e080";
}

.node-expanded .node-icon-ce {
    -webkit-transform: rotate(90deg);
    -ms-transform: rotate(90deg);
    -o-transform: rotate(90deg);
    transform: rotate(90deg);
}


.flexTableContainer {
  position: relative;

}

.flexTable tr,
.flexTable th,
.flexTable td {
  position: relative;
}

.flexTable th,
.flexTable td {
  font-size: 0.9em;
  padding: 0.2em 0.3em 0.2em 0.3em;
}

.flexTable th input,
.flexTable td input {
  width: 100%;
}

.flexTable th input,
.flexTable td:first-child {
  cursor: pointer;
}
.flexTable td:first-child:hover {
  background-color: #008751;
  background-color: #4c6e65;
  color: #f0f0f0;
}

.flexTable th.flxtblColSelected {
  background-color: #4c6e65;
  border-top: 1px solid #4c6e65;
  border-left: 2px solid #4c6e65;
  border-right: 2px solid #4c6e65;
}
.flexTable th.flxtblColMarked {
  border-top: 2px dotted #4c6e65;
  border-left: 2px dotted #4c6e65;
  border-right: 2px dotted #4c6e65;
}
.flexTable td.flxtblColSelected,
.flexTable td.flxtblRowSelected {
  background-color: #f0f0f0 !important;
}
.flexTable td:first-child.flxtblRowSelected {
  color: #ffffff;
  background-color: #4c6e65 !important;
}
.flexTable td:first-child.flxtblRowSelected {
  border-left: 2px solid #4c6e65;
  border-right: 2px solid #4c6e65;
}
.flexTable td:first-child.flxtblRowMarked {
  border-top: none;
  border-bottom: none;
  border-right: 2px dotted #4c6e65;
}
.flexTable td:last-child.flxtblRowSelected {
  border-right: 2px solid #4c6e65;
}
.flexTable td:last-child.flxtblRowMarked {
  border-right: 2px dotted #4c6e65;
}
.flexTable td.flxtblRowSelected {
  border-top: 2px solid #4c6e65;
  border-bottom: 2px solid #4c6e65;
}
.flexTable td.flxtblRowMarked {
  border-top: 2px dotted #4c6e65;
  border-bottom: 2px dotted #4c6e65;
  background: #e8eae9
}
.flexTable td.flxtblColSelected {
  border-left: 2px solid #4c6e65;
  border-right: 2px solid #4c6e65;
}
.flexTable td.flxtblColMarked {
  border-left: 2px dotted #4c6e65;
  border-right: 2px dotted #4c6e65;
}
.flexTable tr:last-child td.flxtblColSelected {
  border-bottom: 2px solid #4c6e65;
}
.flexTable tr:last-child td.flxtblColMarked {
  border-bottom: 2px dotted #4c6e65;
}
.flexTable th.flxtblColSelected input {
  cursor: text;
  color: #ffffff;
}
.flexTable td.cellSelected {
  border: 2px solid #008751;
  border: 2px solid #4c6e65;
}
.flexTable td.cellMarked {
  border: 2px dotted #008751;
  border: 2px dotted #4c6e65;
}

.flexTable td span {
  display: inline-block;
  margin: 0;
}

.flexTable input {
  border: none;
  margin: 0;
  padding: 0;
  background: none;
}
.flexTable thead input {
  font-weight: bold;
}
.flexTable input:focus {
  outline: 0;
}

.flexTable .menuPopupBtn {
  top: 25px;
  left: 20px;
	width: 30px;
	height: 30px;
  margin: 0px;
  background: url(../../../assets/images/icons/menu-h-24x24.png) 2px 2px no-repeat;
  background-color: #fff;
  border: 1px solid #858585;
  border: 1px solid #4c6e65;
  position: absolute;
  cursor: pointer;
}

.flexTableContainer .menuPopup {
  top: 25px;
  left: 20px;
  width: auto;
  height: auto;
  margin: 0;
  padding: 0 0.833em 0.1em 0.3em;
  display: table;
  border: 1px solid #858585;
  border: 1px solid #4c6e65;
  position: absolute;
  cursor: pointer;
}
.flexTableContainer .menuPopup .cutMenuAction {
  background: url(../../../assets/images/icons/cut-60x60.png) 15px 0px no-repeat;
}
.flexTableContainer .menuPopup .copyMenuAction {
  background: url(../../../assets/images/icons/copy-60x60.png) 15px 0px no-repeat;
}
.flexTableContainer .menuPopup .pasteMenuAction {
  background: url(../../../assets/images/icons/paste-60x60.png) 15px 0px no-repeat;
}
.flexTableContainer .menuPopup .pasteDisabledMenuAction {
  background: url(../../../assets/images/icons/paste-disabled-60x60.png) 15px 0px no-repeat;
}
.flexTableContainer .menuPopup .insertColMenuAction {
  background: url(../../../assets/images/icons/insert-col-60x60.png) 15px 0px no-repeat;
}
.flexTableContainer .menuPopup .deleteColMenuAction {
  background: url(../../../assets/images/icons/delete-col-60x60.png) 15px 0px no-repeat;
}
.flexTableContainer .menuPopup .deleteColDisabledMenuAction {
  background: url(../../../assets/images/icons/delete-col-disabled-60x60.png) 15px 0px no-repeat;
  cursor: default;
}
.flexTableContainer .menuPopup .insertRowMenuAction {
  background: url(../../../assets/images/icons/insert-row-60x60.png) 15px 0px no-repeat;
}
.flexTableContainer .menuPopup .deleteRowMenuAction {
  background: url(../../../assets/images/icons/delete-row-60x60.png) 15px 0px no-repeat;
}
.flexTableContainer .menuPopup .deleteRowDisabledMenuAction {
  background: url(../../../assets/images/icons/delete-row-disabled-60x60.png) 15px 0px no-repeat;
  cursor: default;
}

.flexTableContainer .menuPopup div {
  float: left;
  height: 80px;
  width: 90px;
  padding-top: 58px;
  text-align: center;
  cursor: pointer;
  background-color: #fff;
}

body.dialogModal {
  overflow: hidden;
}

.dialogPopUp {

    visibility: hidden;
    position: fixed;
    z-index: 102;
    left: 50%;
    top: 50%;
    padding: 0 0.8333rem 0.8333rem 0.8333rem;
    background: white;
    width: 300px;
    height: 180px;
    margin-top: -90px;
    margin-left: -150px;
    border: 2px solid #000;
}
.dialogOverlay .dialogPopUp {
	border: none;
}
.dialogPopUp.dialogNonModal {
	z-index: 100;
  border: 2px solid #000;
}

.dialogBlockPage {
  width: 100%;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 101;
  height: 5000px;
}
.dialogOverlay .dialogBlockPage {
  background: #000;
	opacity: .6;
  filter: alpha(opacity=50);
}

.dialogOpen {
	visibility: visible;
}




/*

aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa
*/



/* --------------------------------------  navs  ----------------------------------------------- */

* {
    outline: none !important;
}

/*============================================Global============================================ */

ul li {
    list-style: none;
    display: inline-block;
}
li a {
    text-decoration: none;
}
a {
    color: rgba(5, 106, 166, 1);
}
a:hover {
    text-decoration: none;
}
.btn {
    font-family: 'Teko', 'Raleway', sans-serif;
}
p {
    font-family: 'Teko', 'Dosis', sans-serif;
    word-spacing: 3px;
    font-size: 17px;
}
h1, h2, h3, h4, h5, h6 {
    font-family: 'Teko', 'Roboto', sans-serif;
    font-weight: 300;
}
body.stronaglowna {
    background-color: #282828;
}
body.stronaglowna .main-header {
    background-color: #141414;
}
body.stronaglowna .main-header ul.left-menu {
    padding-left: 15px;
    padding-right: 15px;
}
body.stronaglowna .main-header ul.right-menu {
    padding-left: 15px;
    padding-right: 0px;
}
body.stronaglowna .main-header ul {
    margin-bottom: 0px;
    padding-top: 5px;
    padding-bottom: 5px;
}
body.stronaglowna .main-header ul {
    margin-bottom: 0px;
    padding-top: 5px;
    padding-bottom: 5px;
}
body.stronaglowna .main-header ul.left-menu li a {
    color: #C7C7C7;
    text-transform: uppercase;
}
body.stronaglowna .main-header ul.left-menu li a:hover {
    color: #1487B3;
}
body.stronaglowna .main-header ul.right-menu li, body.stronaglowna .main-header ul.right-menu li a {
    color: #353535;
}
.column {
    float: left;
}
.size-1of4 {
    width: 25%;
}
.size-1of3 {
    width: 33.333%;
}
.size-1of2 {
    width: 50%;
}
.size-1of1 {
    width: 100%;
}

/* textcontent */
body {
    font-family: 'Teko', 'Dosis', sans-serif;
}
body.textcontent {
    background-color: #282828;
    color: #C7C7C7;
    font-family: "Teko", "Dosis", sans-serif;
}
body.textcontent p a {
    color: #C7C7C7;
    font-family: "Teko", "Dosis", sans-serif;
    text-decoration: underline;
}
body.textcontent .main-header {
    background-color: #141414;
}
body.textcontent .main-header ul.left-menu {
    padding-left: 15px;
    padding-right: 15px;
}
body.textcontent .main-header ul.right-menu {
    padding-left: 15px;
    padding-right: 0px;
}
body.textcontent .main-header ul {
    margin-bottom: 0px;
    padding-top: 5px;
    padding-bottom: 5px;
}
body.textcontent .main-header ul {
    margin-bottom: 0px;
    padding-top: 5px;
    padding-bottom: 5px;
}
body.textcontent .main-header ul.left-menu li a {
    color: #C7C7C7;
    text-transform: uppercase;
}
body.textcontent .main-header ul.left-menu li a:hover {
    color: #1487B3;
}
body.textcontent .main-header ul.right-menu li, body.textcontent .main-header ul.right-menu li a {
    color: #353535;
}
body.textcontent .socials-links ul {
    padding-left: 0px;
}
body.textcontent .socials-links ul li {
    display: block;
    list-style: none;
    /*padding-left: 0px;*/
    width: 100%;
    /*height: 40px;*/
    margin: 10px 0px;
    padding: 10px 10px;
}
body.textcontent .socials-links ul li img {
    /*float: left;*/
    height: 20px;
    display: inline;
}
body.textcontent .socials-links ul li a {
    text-align: right;
    float: right;
    color: #FFFFFF;
}
body.textcontent .socials-links ul li.facebook {
    background-color: #46629E;
}
body.textcontent .socials-links ul li.facebook a {
    color: #FFFFFF;
}
body.textcontent .socials-links ul li.instagram {
    background-color: #3f729b;
}
body.textcontent .socials-links ul li.instagram a {
    color: #DEE8F0;
}
body.textcontent .socials-links ul li.devianart {
    background-color: #4B5D50;
}
body.textcontent .socials-links ul li.devianart a {
    color: #C8DA2C;
}
body.textcontent .socials-links ul li.maxmodels {
    background-color: #3D3D3F;
}
body.textcontent .socials-links ul li.maxmodels a {
    color: #FFFFFF;
}
body.textcontent .socials-links ul li.pinterest {
    background-color: #CB2027;
}
body.textcontent .socials-links ul li.pinterest a {
    color: #FFFFFF;
}
body.textcontent .socials-links ul li.weheartit {
    background-color: #EEEEEE;
}
body.textcontent .socials-links ul li.weheartit a {
    color: #565656;
}
body.textcontent .socials-links ul li.tumbl {
    background-color: #35465D;
}
body.textcontent .socials-links ul li.tumbl a {
    color: #FFFFFF;
}

/* textcontent */


/* videocategory */

body.videocategory {
    background-color: #000000;
}
body.videocategory .navs {
    background-color: #000000;
    padding: 10px 0px;
}
body.videocategory .main-header {
    background-color: #ffffff;
}
body.videocategory .main-header ul.left-menu, body.videocategory .navs ul {
    padding-left: 15px;
    padding-right: 0px;
}
body.videocategory .main-header ul.left-menu {
    padding-left: 15px;
    padding-right: 15px;
}
body.videocategory .main-header ul.right-menu {
    padding-left: 15px;
    padding-right: 0px;
}
body.videocategory .main-header ul {
    margin-bottom: 0px;
    padding-top: 5px;
    padding-bottom: 5px;
}
body.videocategory .main-header ul {
    margin-bottom: 0px;
    padding-top: 5px;
    padding-bottom: 5px;
}
body.videocategory .main-header ul.left-menu li a {
    color: #000000;
    text-transform: uppercase;
}
body.videocategory .main-header ul.left-menu li a:hover {
    color: #CC181E;
}
body.videocategory .main-header ul.right-menu li, body.videocategory .main-header ul.right-menu li a {
    color: #000000;
}
#videogrid {
    margin-left: 5px;
    margin-right: 5px;
}
#videogrid .object {
    margin-top: 10px;
    margin-left: 5px;
    margin-right: 5px;
}
#videogrid .object ul {
    padding-left: 0px;
}
#videogrid .object ul li {
    display: list-item;
    padding: 10px 10px;
    color: #000000;
}
#videogrid .object ul li.data-object {
    padding: 10px 10px;
    border-bottom: 1px solid #000000;
}
#videogrid .object ul li.data-attr {
    padding: 5px 10px;
    border-bottom: 1px solid #000000;
}
#videogrid .object ul li.data-attr i {
    padding: 0px 30px 0px 0px;
    display: inline;
    background-repeat: no-repeat;
    background-position: 0% 50%;
    background-size: contain;
    width: 100%;
    height: 100%;
}
#videogrid .object ul li.data-attr span {
    display: block;
    padding-left: 30px;
    margin-top: -20px;
}
#videogrid .object ul li.data-attr p {
    display: block;
    padding-left: 30px;
    margin-top: -20px;
}
#videogrid .object ul li.data-attr i.icon-name {
    background-image: url('../../../assets/images/page/name-icon.svg');
}
#videogrid .object ul li.data-attr i.icon-person {
    background-image: url('../../../assets/images/page/person-icon.svg');
}
#videogrid .object ul li.data-attr i.icon-place {
    background-image: url('../../../assets/images/page/place-icon.svg');
}
#videogrid .object ul li.data-attr i.icon-calendar {
    background-image: url('../../../assets/images/page/calendar-icon.svg');
}
#videogrid .object ul li.data-attr i.icon-nsfw {
    background-image: url('../../../assets/images/page/nsfw-icon.svg');
}
#videogrid .object ul li.data-attr i.icon-paleta {
    background-image: url('../../../assets/images/page/paleta-icon.svg');
}
#videogrid .object ul li.data-link {
    padding: 5px 10px;
    text-align: center;
}
#videogrid .object ul li.data-link a {
    color: #000000;
    text-transform: uppercase;
    font-weight: bold;
}
#videogrid .object ul li.data-link a:hover {
    color: #CC181E;
}
#videogrid .object {
    background-color: #ffffff;
}

/* Configurate salvattore with media queries */

@media screen and (max-width: 450px) {
    #videogrid[data-columns]::before {
        content: '1 .column.size-1of1';
    }
}
@media screen and (min-width: 451px) and (max-width: 700px) {
    #videogrid[data-columns]::before {
        content: '2 .column.size-1of2';
    }
}
@media screen and (min-width: 701px) {
    #videogrid[data-columns]::before {
        content: '3 .column.size-1of3';
    }
}

/*
@media screen and (min-width: 851px) {
    #videogrid[data-columns]::before {
        content: '4 .column.size-1of4';
    }
}*/


/* videocategory */


/* videogallery */

body.videogallery {
    /*background-image: url('../../../assets/images/page/bg-design.svg');
    background-repeat: repeat;*/
    background-color: #000000;
}
body.videogallery .navs {
    background-color: #000000;
    padding: 10px 0px;
}
body.videogallery .main-header {
    background-color: #FFFFFF;
}
body.videogallery .main-header ul.left-menu, body.videogallery .navs ul {
    padding-left: 15px;
    padding-right: 0px;
}
body.videogallery .main-header ul.left-menu {
    padding-left: 15px;
    padding-right: 15px;
}
body.videogallery .main-header ul.right-menu {
    padding-left: 15px;
    padding-right: 0px;
}
body.videogallery .main-header ul {
    margin-bottom: 0px;
    padding-top: 5px;
    padding-bottom: 5px;
}
body.videogallery .main-header ul {
    margin-bottom: 0px;
    padding-top: 5px;
    padding-bottom: 5px;
}
body.videogallery .main-header ul.left-menu li {
    color: #000000;
    text-transform: uppercase;
}
body.videogallery .main-header ul.left-menu li a {
    color: #000000;
    text-transform: uppercase;
}
body.videogallery .main-header ul.left-menu li a:hover {
    color: #CC181E;
}
body.videogallery .main-header ul.right-menu li, body.videogallery .main-header ul.right-menu li a {
    color: #000000;
}

body.videogallery .content-object.left-menu {
    margin-top: 10px;
    margin-left: 10px;
    margin-right: 10px;
    padding-left: 0px;
    padding-right: 0px;
}
body.videogallery .content-object.left-menu ul {
    padding-left: 0px;
    margin-bottom: 0px;
}
body.videogallery .content-object.left-menu ul li {
    display: list-item;
    padding: 10px 10px;
    color: #000000;
}
#videogallerygrid {
    margin-left: 0px;
    margin-right: 0px;
    overflow: hidden;
    position: relative;
    width: 100%;
    display: none;/*
    padding-left: 0px;
    padding-right: 0px;*/
}
#videogallerygrid .content-object.objects {
    margin-top: 10px;
    margin-left: 0px;
    margin-right: 0px;
    overflow: auto;
    width: 100%;
}
#videogallerygrid .content-object.objects ul {
    padding-left: 0px;
    margin-bottom: 0px;
    height: 100%;
    /* mobile */
    margin: 0 auto;
    width: 100%;
    /* mobile */
}
#videogallerygrid .content-object.objects ul li {
    /*display: list-item;*/
    /*padding: 10px 10px;*/
    color: #000000;
    float: left;
    height: 100%;
    /* mobile */
    position: relative;
    background-color: #ffffff;
    padding: 10px;
    margin-left: 5px;
    margin-right: 5px;
}


body.videogallery .content-object.left-menu ul li.data-object {
    padding: 10px 10px;
    border-bottom: 1px solid #000000;
}
body.videogallery .content-object.left-menu ul li.data-object img {
    width: 100%;
}
body.videogallery .content-object.left-menu ul li.data-attr {
    padding: 5px 10px;
    border-bottom: 1px solid #000000;
}
body.videogallery .content-object.left-menu ul li.data-attr i {
    padding: 0px 30px 0px 0px;
    display: inline;
    background-repeat: no-repeat;
    background-position: 0% 50%;
    background-size: contain;
    width: 100%;
    height: 100%;
}
body.videogallery .content-object.left-menu ul li.data-attr span {
    display: block;
    padding-left: 30px;
    margin-top: -20px;
}
body.videogallery .content-object.left-menu ul li.data-attr p {
    display: block;
    padding-left: 30px;
    margin-top: -20px;
}
body.videogallery .content-object.left-menu ul li.data-attr i.icon-name {
    background-image: url('../../../assets/images/page/name-icon.svg');
}
body.videogallery .content-object.left-menu ul li.data-attr i.icon-person {
    background-image: url('../../../assets/images/page/person-icon.svg');
}
body.videogallery .content-object.left-menu ul li.data-attr i.icon-place {
    background-image: url('../../../assets/images/page/place-icon.svg');
}
body.videogallery .content-object.left-menu ul li.data-attr i.icon-calendar {
    background-image: url('../../../assets/images/page/calendar-icon.svg');
}
body.videogallery .content-object.left-menu ul li.data-attr i.icon-nsfw {
    background-image: url('../../../assets/images/page/nsfw-icon.svg');
}
body.videogallery .content-object.left-menu ul li.data-attr i.icon-paleta {
    background-image: url('../../../assets/images/page/paleta-icon.svg');
}
body.videogallery .content-object.left-menu ul li.data-attr i.icon-comment {
    background-image: url('../../../assets/images/page/comment-icon.svg');
}
body.videogallery .content-object.left-menu ul li.data-link {
    padding: 5px 10px;
    text-align: center;
    border-top: 1px solid #000000;
    width: 100%;
}
body.videogallery .content-object.left-menu ul li.data-link a {
    color: #000000;
    text-transform: uppercase;
    font-weight: bold;
}
body.videogallery .content-object.left-menu ul li.data-link a:hover {
    color: #CC181E;
}
body.videogallery .content-object.left-menu {
    background-color: #ffffff;
}


/* videogallery */

/* designcategory */

body.designcategory {
    background-image: url('../../../assets/images/page/bg-design.svg');
    background-repeat: repeat;
}
body.designcategory .navs {
    background-color: #343434;
    padding: 10px 0px;
}
body.designcategory .main-header {
    background-color: #2D2D2D;
}
body.designcategory .main-header ul.left-menu, body.designcategory .navs ul {
    padding-left: 15px;
    padding-right: 0px;
}
body.designcategory .main-header ul.left-menu {
    padding-left: 15px;
    padding-right: 15px;
}
body.designcategory .main-header ul.right-menu {
    padding-left: 15px;
    padding-right: 0px;
}
body.designcategory .main-header ul {
    margin-bottom: 0px;
    padding-top: 5px;
    padding-bottom: 5px;
}
body.designcategory .main-header ul {
    margin-bottom: 0px;
    padding-top: 5px;
    padding-bottom: 5px;
}
body.designcategory .main-header ul.left-menu li a {
    color: #C7C7C7;
    text-transform: uppercase;
}
body.designcategory .main-header ul.left-menu li a:hover {
    color: #1487B3;
}
body.designcategory .main-header ul.right-menu li, body.designcategory .main-header ul.right-menu li a {
    color: #C7C7C7;
}
#designgrid {
    margin-left: 5px;
    margin-right: 5px;
}
#designgrid .object {
    margin-top: 10px;
    margin-left: 5px;
    margin-right: 5px;
}
#designgrid .object ul {
    padding-left: 0px;
}
#designgrid .object ul li {
    display: list-item;
    padding: 10px 10px;
    color: #494949;
}
#designgrid .object ul li.data-object {
    padding: 10px 10px;
    border-bottom: 1px solid #C6C6C6;
}
#designgrid .object ul li.data-object img {
    width: 100%;
}
#designgrid .object ul li.data-attr {
    padding: 5px 10px;
    border-bottom: 1px solid #C6C6C6;
}
#designgrid .object ul li.data-attr i {
    padding: 0px 30px 0px 0px;
    display: inline;
    background-repeat: no-repeat;
    background-position: 0% 50%;
    background-size: contain;
    width: 100%;
    height: 100%;
}
#designgrid .object ul li.data-attr span {
    display: block;
    padding-left: 30px;
    margin-top: -20px;
}
#designgrid .object ul li.data-attr p {
    display: block;
    padding-left: 30px;
    margin-top: -20px;
}
#designgrid .object ul li.data-attr i.icon-name {
    background-image: url('../../../assets/images/page/name-icon.svg');
}
#designgrid .object ul li.data-attr i.icon-person {
    background-image: url('../../../assets/images/page/person-icon.svg');
}
#designgrid .object ul li.data-attr i.icon-place {
    background-image: url('../../../assets/images/page/place-icon.svg');
}
#designgrid .object ul li.data-attr i.icon-calendar {
    background-image: url('../../../assets/images/page/calendar-icon.svg');
}
#designgrid .object ul li.data-attr i.icon-nsfw {
    background-image: url('../../../assets/images/page/nsfw-icon.svg');
}
#designgrid .object ul li.data-attr i.icon-paleta {
    background-image: url('../../../assets/images/page/paleta-icon.svg');
}
#designgrid .object ul li.data-link {
    padding: 5px 10px;
    text-align: center;
}
#designgrid .object ul li.data-link a {
    color: #1487B3;
    text-transform: uppercase;
    font-weight: bold;
}
#designgrid .object ul li.data-link a:hover {
    color: #494949;
}
#designgrid .object {
    background-color: #ffffff;
}

/* Configurate salvattore with media queries */

@media screen and (max-width: 450px) {
    #designgrid[data-columns]::before {
        content: '1 .column.size-1of1';
    }
}
@media screen and (min-width: 451px) and (max-width: 700px) {
    #designgrid[data-columns]::before {
        content: '2 .column.size-1of2';
    }
}
@media screen and (min-width: 701px) {
    #designgrid[data-columns]::before {
        content: '3 .column.size-1of3';
    }
}

/*
@media screen and (min-width: 851px) {
    #videogrid[data-columns]::before {
        content: '4 .column.size-1of4';
    }
}*/


/* designcategory */


/* designgallery */

body.designgallery {
    background-image: url('../../../assets/images/page/bg-design.svg');
    background-repeat: repeat;
}
body.designgallery .navs {
    background-color: #343434;
    padding: 10px 0px;
}
body.designgallery .main-header {
    background-color: #2D2D2D;
}
body.designgallery .main-header ul.left-menu, body.designgallery .navs ul {
    padding-left: 15px;
    padding-right: 0px;
}
body.designgallery .main-header ul.left-menu {
    padding-left: 15px;
    padding-right: 15px;
}
body.designgallery .main-header ul.right-menu {
    padding-left: 15px;
    padding-right: 0px;
}
body.designgallery .main-header ul {
    margin-bottom: 0px;
    padding-top: 5px;
    padding-bottom: 5px;
}
body.designgallery .main-header ul {
    margin-bottom: 0px;
    padding-top: 5px;
    padding-bottom: 5px;
}
body.designgallery .main-header ul.left-menu li {
    color: #C7C7C7;
    text-transform: uppercase;
}
body.designgallery .main-header ul.left-menu li a {
    color: #C7C7C7;
    text-transform: uppercase;
}
body.designgallery .main-header ul.left-menu li a:hover {
    color: #1487B3;
}
body.designgallery .main-header ul.right-menu li, body.designgallery .main-header ul.right-menu li a {
    color: #C7C7C7;
}
#designgallerygrid {
    margin-left: 0px;
    margin-right: 0px;
    overflow: hidden;
    position: relative;
    width: 100%;
    display: none;/*
    padding-left: 0px;
    padding-right: 0px;*/
}
body.designgallery .content-object.left-menu {
    margin-top: 10px;
    margin-left: 10px;
    margin-right: 10px;
    padding-left: 0px;
    padding-right: 0px;
}
body.designgallery .content-object.left-menu ul {
    padding-left: 0px;
    margin-bottom: 0px;
}
body.designgallery .content-object.left-menu ul li {
    display: list-item;
    padding: 10px 10px;
    color: #494949;
}
#designgallerygrid .content-object.objects {
    margin-top: 10px;
    margin-left: 0px;
    margin-right: 0px;
    overflow: auto;
    width: 100%;
}
#designgallerygrid .content-object.objects ul {
    padding-left: 0px;
    margin-bottom: 0px;
    height: 100%;
    /* mobile */
    margin: 0 auto;
    width: 100%;
    /* mobile */
}
#designgallerygrid .content-object.objects ul li {
    /*display: list-item;*/
    /*padding: 10px 10px;*/
    color: #494949;
    float: left;
    height: 100%;
    /* mobile */
    position: relative;
    background-color: #ffffff;
    padding: 10px;
    margin-left: 5px;
    margin-right: 5px;
}
#designgallerygrid .content-object.objects ul li a:hover:before {
    width: 50px;
    height: 50px;
    position: absolute;
    top: 20px;
    left: 20px;
    background-image: url('../../../assets/images/page/lupa-icon.svg');
    background-repeat: no-repeat;
    z-index: 300;
    content: ' ';
}

body.designgallery .content-object.left-menu ul li.data-object {
    padding: 10px 10px;
    border-bottom: 1px solid #C6C6C6;
}
body.designgallery .content-object.left-menu ul li.data-object img {
    width: 100%;
}
body.designgallery .content-object.left-menu ul li.data-attr {
    padding: 5px 10px;
    border-bottom: 1px solid #C6C6C6;
}
body.designgallery .content-object.left-menu ul li.data-attr i {
    padding: 0px 30px 0px 0px;
    display: inline;
    background-repeat: no-repeat;
    background-position: 0% 50%;
    background-size: contain;
    width: 100%;
    height: 100%;
}
body.designgallery .content-object.left-menu ul li.data-attr span {
    display: block;
    padding-left: 30px;
    margin-top: -20px;
}
body.designgallery .content-object.left-menu ul li.data-attr p {
    display: block;
    padding-left: 30px;
    margin-top: -20px;
}
body.designgallery .content-object.left-menu ul li.data-attr i.icon-name {
    background-image: url('../../../assets/images/page/name-icon.svg');
}
body.designgallery .content-object.left-menu ul li.data-attr i.icon-person {
    background-image: url('../../../assets/images/page/person-icon.svg');
}
body.designgallery .content-object.left-menu ul li.data-attr i.icon-place {
    background-image: url('../../../assets/images/page/place-icon.svg');
}
body.designgallery .content-object.left-menu ul li.data-attr i.icon-calendar {
    background-image: url('../../../assets/images/page/calendar-icon.svg');
}
body.designgallery .content-object.left-menu ul li.data-attr i.icon-nsfw {
    background-image: url('../../../assets/images/page/nsfw-icon.svg');
}
body.designgallery .content-object.left-menu ul li.data-attr i.icon-paleta {
    background-image: url('../../../assets/images/page/paleta-icon.svg');
}
body.designgallery .content-object.left-menu ul li.data-attr i.icon-comment {
    background-image: url('../../../assets/images/page/comment-icon.svg');
}
body.designgallery .content-object.left-menu ul li.data-link {
    padding: 5px 10px;
    text-align: center;
    border-top: 1px solid #C6C6C6;
    width: 100%;
}
body.designgallery .content-object.left-menu ul li.data-link a {
    color: #1487B3;
    text-transform: uppercase;
    font-weight: bold;
}
body.designgallery .content-object.left-menu ul li.data-link a:hover {
    color: #494949;
}
body.designgallery .content-object.left-menu {
    background-color: #ffffff;
}


/* designgallery */


/* obrazycategory */

body.obrazycategory {
    /*background-image: url('../../../assets/images/page/bg-design.svg');
    background-repeat: repeat;*/
    background-color: #D1D1D1;
}
body.obrazycategory .navs {
    background-color: #2B2C2C;
    padding: 10px 0px;
}
body.obrazycategory .main-header {
    background-color: #FFFFFF;
}
body.obrazycategory .main-header ul.left-menu, body.obrazycategory .navs ul {
    padding-left: 15px;
    padding-right: 0px;
}
body.obrazycategory .main-header ul.left-menu {
    padding-left: 15px;
    padding-right: 15px;
}
body.obrazycategory .main-header ul.right-menu {
    padding-left: 15px;
    padding-right: 0px;
}
body.obrazycategory .main-header ul {
    margin-bottom: 0px;
    padding-top: 5px;
    padding-bottom: 5px;
}
body.obrazycategory .main-header ul {
    margin-bottom: 0px;
    padding-top: 5px;
    padding-bottom: 5px;
}
body.obrazycategory .main-header ul.left-menu li a {
    color: #2B2C2C;
    text-transform: uppercase;
}
body.obrazycategory .main-header ul.left-menu li a:hover {
    color: #1788B3;
}
body.obrazycategory .main-header ul.right-menu li, body.obrazycategory .main-header ul.right-menu li a {
    color: #2B2C2C;
}
#obrazygrid {
    margin-left: 5px;
    margin-right: 5px;
}
#obrazygrid .object {
    margin-top: 10px;
    margin-left: 5px;
    margin-right: 5px;
}
#obrazygrid .object ul {
    padding-left: 0px;
}
#obrazygrid .object ul li {
    display: list-item;
    padding: 10px 10px;
    color: #2B2C2C;
}
#obrazygrid .object ul li.data-object {
    padding: 10px 10px;
    border-bottom: 1px solid #D1D1D1;
}
#obrazygrid .object ul li.data-object img {
    width: 100%;
}
#obrazygrid .object ul li.data-attr {
    padding: 5px 10px;
    border-bottom: 1px solid #D1D1D1;
}
#obrazygrid .object ul li.data-attr i {
    padding: 0px 30px 0px 0px;
    display: inline;
    background-repeat: no-repeat;
    background-position: 0% 50%;
    background-size: contain;
    width: 100%;
    height: 100%;
}
#obrazygrid .object ul li.data-attr span {
    display: block;
    padding-left: 30px;
    margin-top: -20px;
}
#obrazygrid .object ul li.data-attr p {
    display: block;
    padding-left: 30px;
    margin-top: -20px;
}
#obrazygrid .object ul li.data-attr i.icon-name {
    background-image: url('../../../assets/images/page/name-icon.svg');
}
#obrazygrid .object ul li.data-attr i.icon-person {
    background-image: url('../../../assets/images/page/person-icon.svg');
}
#obrazygrid .object ul li.data-attr i.icon-place {
    background-image: url('../../../assets/images/page/place-icon.svg');
}
#obrazygrid .object ul li.data-attr i.icon-calendar {
    background-image: url('../../../assets/images/page/calendar-icon.svg');
}
#obrazygrid .object ul li.data-attr i.icon-nsfw {
    background-image: url('../../../assets/images/page/nsfw-icon.svg');
}
#obrazygrid .object ul li.data-attr i.icon-paleta {
    background-image: url('../../../assets/images/page/paleta-icon.svg');
}
#obrazygrid .object ul li.data-link {
    padding: 5px 10px;
    text-align: center;
}
#obrazygrid .object ul li.data-link a {
    color: #2B2C2C;
    text-transform: uppercase;
    font-weight: bold;
}
#obrazygrid .object ul li.data-link a:hover {
    color: #1788B3;
}
#obrazygrid .object {
    background-color: #ffffff;
}

/* Configurate salvattore with media queries */

@media screen and (max-width: 450px) {
    #obrazygrid[data-columns]::before {
        content: '1 .column.size-1of1';
    }
}
@media screen and (min-width: 451px) and (max-width: 700px) {
    #obrazygrid[data-columns]::before {
        content: '2 .column.size-1of2';
    }
}
@media screen and (min-width: 701px) {
    #obrazygrid[data-columns]::before {
        content: '3 .column.size-1of3';
    }
}

/*
@media screen and (min-width: 851px) {
    #videogrid[data-columns]::before {
        content: '4 .column.size-1of4';
    }
}*/


/* obrazycategory */


/* obrazygallery */

body.obrazygallery {
    /*background-image: url('../../../assets/images/page/bg-design.svg');
    background-repeat: repeat;*/
    background-color: #D1D1D1;
}
body.obrazygallery .navs {
    background-color: #2B2C2C;
    padding: 10px 0px;
}
body.obrazygallery .main-header {
    background-color: #FFFFFF;
}
body.obrazygallery .main-header ul.left-menu, body.obrazygallery .navs ul {
    padding-left: 15px;
    padding-right: 0px;
}
body.obrazygallery .main-header ul.left-menu {
    padding-left: 15px;
    padding-right: 15px;
}
body.obrazygallery .main-header ul.right-menu {
    padding-left: 15px;
    padding-right: 0px;
}
body.obrazygallery .main-header ul {
    margin-bottom: 0px;
    padding-top: 5px;
    padding-bottom: 5px;
}
body.obrazygallery .main-header ul {
    margin-bottom: 0px;
    padding-top: 5px;
    padding-bottom: 5px;
}
body.obrazygallery .main-header ul.left-menu li {
    color: #2B2C2C;
    text-transform: uppercase;
}
body.obrazygallery .main-header ul.left-menu li a {
    color: #2B2C2C;
    text-transform: uppercase;
}
body.obrazygallery .main-header ul.left-menu li a:hover {
    color: #1788B3;
}
body.obrazygallery .main-header ul.right-menu li, body.obrazygallery .main-header ul.right-menu li a {
    color: #2B2C2C;
}

body.obrazygallery .content-object.left-menu {
    margin-top: 10px;
    margin-left: 10px;
    margin-right: 10px;
    padding-left: 0px;
    padding-right: 0px;
}
body.obrazygallery .content-object.left-menu ul {
    padding-left: 0px;
    margin-bottom: 0px;
}
body.obrazygallery .content-object.left-menu ul li {
    display: list-item;
    padding: 10px 10px;
    color: #2B2C2C;
}
#obrazygallerygrid {
    margin-left: 0px;
    margin-right: 0px;
    overflow: hidden;
    position: relative;
    width: 100%;
    display: none;/*
    padding-left: 0px;
    padding-right: 0px;*/
}
#obrazygallerygrid .content-object.objects {
    margin-top: 10px;
    margin-left: 0px;
    margin-right: 0px;
    overflow: auto;
    width: 100%;
}
#obrazygallerygrid .content-object.objects ul {
    padding-left: 0px;
    margin-bottom: 0px;
    height: 100%;
    /* mobile */
    margin: 0 auto;
    width: 100%;
    /* mobile */
}
#obrazygallerygrid .content-object.objects ul li {
    /*display: list-item;*/
    /*padding: 10px 10px;*/
    color: #2B2C2C;
    float: left;
    height: 100%;
    /* mobile */
    position: relative;
    background-color: #ffffff;
    padding: 10px;
    margin-left: 5px;
    margin-right: 5px;
}
#obrazygallerygrid .content-object.objects ul li a:hover:before {
    width: 50px;
    height: 50px;
    position: absolute;
    top: 20px;
    left: 20px;
    background-image: url('../../../assets/images/page/lupa-icon.svg');
    background-repeat: no-repeat;
    z-index: 300;
    content: ' ';
}

body.obrazygallery .content-object.left-menu ul li.data-object {
    padding: 10px 10px;
    border-bottom: 1px solid #D1D1D1;
}
body.obrazygallery .content-object.left-menu ul li.data-object img {
    width: 100%;
}
body.obrazygallery .content-object.left-menu ul li.data-attr {
    padding: 5px 10px;
    border-bottom: 1px solid #D1D1D1;
}
body.obrazygallery .content-object.left-menu ul li.data-attr i {
    padding: 0px 30px 0px 0px;
    display: inline;
    background-repeat: no-repeat;
    background-position: 0% 50%;
    background-size: contain;
    width: 100%;
    height: 100%;
}
body.obrazygallery .content-object.left-menu ul li.data-attr span {
    display: block;
    padding-left: 30px;
    margin-top: -20px;
}
body.obrazygallery .content-object.left-menu ul li.data-attr p {
    display: block;
    padding-left: 30px;
    margin-top: -20px;
}
body.obrazygallery .content-object.left-menu ul li.data-attr i.icon-name {
    background-image: url('../../../assets/images/page/name-icon.svg');
}
body.obrazygallery .content-object.left-menu ul li.data-attr i.icon-person {
    background-image: url('../../../assets/images/page/person-icon.svg');
}
body.obrazygallery .content-object.left-menu ul li.data-attr i.icon-place {
    background-image: url('../../../assets/images/page/place-icon.svg');
}
body.obrazygallery .content-object.left-menu ul li.data-attr i.icon-calendar {
    background-image: url('../../../assets/images/page/calendar-icon.svg');
}
body.obrazygallery .content-object.left-menu ul li.data-attr i.icon-nsfw {
    background-image: url('../../../assets/images/page/nsfw-icon.svg');
}
body.obrazygallery .content-object.left-menu ul li.data-attr i.icon-paleta {
    background-image: url('../../../assets/images/page/paleta-icon.svg');
}
body.obrazygallery .content-object.left-menu ul li.data-attr i.icon-comment {
    background-image: url('../../../assets/images/page/comment-icon.svg');
}
body.obrazygallery .content-object.left-menu ul li.data-link {
    padding: 5px 10px;
    text-align: center;
    border-top: 1px solid #D1D1D1;
    width: 100%;
}
body.obrazygallery .content-object.left-menu ul li.data-link a {
    color: #2B2C2C;
    text-transform: uppercase;
    font-weight: bold;
}
body.obrazygallery .content-object.left-menu ul li.data-link a:hover {
    color: #1788B3;
}
body.obrazygallery .content-object.left-menu {
    background-color: #ffffff;
}


/* obrazygallery */


/* fotografiacategory */

body.fotografiacategory {
    /*background-image: url('../../../assets/images/page/bg-design.svg');
    background-repeat: repeat;*/
    background-color: #282828;
}
body.fotografiacategory .navs {
    background-color: #1B1B1B;
    padding: 10px 0px;
}
body.fotografiacategory .main-header {
    background-color: #141414;
}
body.fotografiacategory .main-header ul.left-menu, body.fotografiacategory .navs ul {
    padding-left: 15px;
    padding-right: 0px;
}
body.fotografiacategory .main-header ul.left-menu {
    padding-left: 15px;
    padding-right: 15px;
}
body.fotografiacategory .main-header ul.right-menu {
    padding-left: 15px;
    padding-right: 0px;
}
body.fotografiacategory .main-header ul {
    margin-bottom: 0px;
    padding-top: 5px;
    padding-bottom: 5px;
}
body.fotografiacategory .main-header ul {
    margin-bottom: 0px;
    padding-top: 5px;
    padding-bottom: 5px;
}
body.fotografiacategory .main-header ul.left-menu li a {
    color: #C7C7C7;
    text-transform: uppercase;
}
body.fotografiacategory .main-header ul.left-menu li a:hover {
    color: #1487B3;
}
body.fotografiacategory .main-header ul.right-menu li, body.fotografiacategory .main-header ul.right-menu li a {
    color: #353535;
}
#fotografiagrid {
    margin-left: 5px;
    margin-right: 5px;
}
#fotografiagrid .object {
    margin-top: 10px;
    margin-left: 5px;
    margin-right: 5px;
}
#fotografiagrid .object ul {
    padding-left: 0px;
}
#fotografiagrid .object ul li {
    display: list-item;
    padding: 10px 10px;
    color: #494949;
}
#fotografiagrid .object ul li.data-object {
    padding: 10px 10px;
    border-bottom: 1px solid #C6C6C6;
}
#fotografiagrid .object ul li.data-object img {
    width: 100%;
}
#fotografiagrid .object ul li.data-attr {
    padding: 5px 10px;
    border-bottom: 1px solid #C6C6C6;
}
#fotografiagrid .object ul li.data-attr i {
    padding: 0px 30px 0px 0px;
    display: inline;
    background-repeat: no-repeat;
    background-position: 0% 50%;
    background-size: contain;
    width: 100%;
    height: 100%;
}
#fotografiagrid .object ul li.data-attr span {
    display: block;
    padding-left: 30px;
    margin-top: -20px;
}
#fotografiagrid .object ul li.data-attr p {
    display: block;
    padding-left: 30px;
    margin-top: -20px;
}
#fotografiagrid .object ul li.data-attr i.icon-name {
    background-image: url('../../../assets/images/page/name-icon.svg');
}
#fotografiagrid .object ul li.data-attr i.icon-person {
    background-image: url('../../../assets/images/page/person-icon.svg');
}
#fotografiagrid .object ul li.data-attr i.icon-place {
    background-image: url('../../../assets/images/page/place-icon.svg');
}
#fotografiagrid .object ul li.data-attr i.icon-calendar {
    background-image: url('../../../assets/images/page/calendar-icon.svg');
}
#fotografiagrid .object ul li.data-attr i.icon-nsfw {
    background-image: url('../../../assets/images/page/nsfw-icon.svg');
}
#fotografiagrid .object ul li.data-attr i.icon-paleta {
    background-image: url('../../../assets/images/page/paleta-icon.svg');
}
#fotografiagrid .object ul li.data-link {
    padding: 5px 10px;
    text-align: center;
}
#fotografiagrid .object ul li.data-link a {
    color: #1487B3;
    text-transform: uppercase;
    font-weight: bold;
}
#fotografiagrid .object ul li.data-link a:hover {
    color: #282828;
}
#fotografiagrid .object {
    background-color: #ffffff;
}

/* Configurate salvattore with media queries */

@media screen and (max-width: 450px) {
    #fotografiagrid[data-columns]::before {
        content: '1 .column.size-1of1';
    }
}
@media screen and (min-width: 451px) and (max-width: 700px) {
    #fotografiagrid[data-columns]::before {
        content: '2 .column.size-1of2';
    }
}
@media screen and (min-width: 701px) and (max-width: 850px) {
    #fotografiagrid[data-columns]::before {
        content: '3 .column.size-1of3';
    }
}
@media screen and (min-width: 851px) {
    #fotografiagrid[data-columns]::before {
        content: '4 .column.size-1of4';
    }
}

/* fotografiacategory */



/* fotogallery */

body.fotogallery {
    /*background-image: url('../../../assets/images/page/bg-design.svg');
    background-repeat: repeat;*/
    background-color: #282828;
}
body.fotogallery .navs {
    background-color: #1B1B1B;
    padding: 10px 0px;
}
body.fotogallery .main-header {
    background-color: #141414;
}
body.fotogallery .main-header ul.left-menu, body.fotogallery .navs ul {
    padding-left: 15px;
    padding-right: 0px;
}
body.fotogallery .main-header ul.left-menu {
    padding-left: 15px;
    padding-right: 15px;
}
body.fotogallery .main-header ul.right-menu {
    padding-left: 15px;
    padding-right: 0px;
}
body.fotogallery .main-header ul {
    margin-bottom: 0px;
    padding-top: 5px;
    padding-bottom: 5px;
}
body.fotogallery .main-header ul {
    margin-bottom: 0px;
    padding-top: 5px;
    padding-bottom: 5px;
}
body.fotogallery .main-header ul.left-menu li a {
    color: #C7C7C7;
    text-transform: uppercase;
}
body.fotogallery .main-header ul.left-menu li a:hover {
    color: #1487B3;
}
body.fotogallery .main-header ul.right-menu li, body.fotogallery .main-header ul.right-menu li a {
    color: #353535;
}

body.fotogallery .content-object.left-menu {
    margin-top: 10px;
    margin-left: 10px;
    margin-right: 10px;
    padding-left: 0px;
    padding-right: 0px;
}
body.fotogallery .content-object.left-menu ul {
    padding-left: 0px;
    margin-bottom: 0px;
}
body.fotogallery .content-object.left-menu ul li {
    display: list-item;
    padding: 10px 10px;
    color: #494949;
}
#fotogallerygrid {
    margin-left: 0px;
    margin-right: 0px;
    overflow: hidden;
    position: relative;
    width: 100%;
    display: none;/*
    padding-left: 0px;
    padding-right: 0px;*/
}
#fotogallerygrid .content-object.objects {
    margin-top: 10px;
    margin-left: 0px;
    margin-right: 0px;
    overflow: auto;
    width: 100%;
}
#fotogallerygrid .content-object.objects ul {
    padding-left: 0px;
    margin-bottom: 0px;
    height: 100%;
    /* mobile */
    margin: 0 auto;
    width: 100%;
    /* mobile */
}
#fotogallerygrid .content-object.objects ul li {
    /*display: list-item;*/
    /*padding: 10px 10px;*/
    color: #494949;
    float: left;
    height: 100%;
    /* mobile */
    position: relative;
    background-color: #ffffff;
    padding: 10px;
    margin-left: 5px;
    margin-right: 5px;
}
#fotogallerygrid .content-object.objects ul li a:hover:before {
    width: 50px;
    height: 50px;
    position: absolute;
    top: 20px;
    left: 20px;
    background-image: url('../../../assets/images/page/lupa-icon.svg');
    background-repeat: no-repeat;
    z-index: 300;
    content: ' ';
}

body.fotogallery .content-object.left-menu ul li.data-attr {
    padding: 5px 10px;
    border-bottom: 1px solid #C6C6C6;
}
body.fotogallery .content-object.left-menu ul li.data-attr i {
    padding: 0px 30px 0px 0px;
    display: inline;
    background-repeat: no-repeat;
    background-position: 0% 50%;
    background-size: contain;
    width: 100%;
    height: 100%;
}
body.fotogallery .content-object.left-menu ul li.data-attr span {
    display: block;
    padding-left: 30px;
    margin-top: -20px;
}
body.fotogallery .content-object.left-menu ul li.data-attr p {
    display: block;
    padding-left: 30px;
    margin-top: -20px;
}
body.fotogallery .content-object.left-menu ul li.data-attr i.icon-name {
    background-image: url('../../../assets/images/page/name-icon.svg');
}
body.fotogallery .content-object.left-menu ul li.data-attr i.icon-person {
    background-image: url('../../../assets/images/page/person-icon.svg');
}
body.fotogallery .content-object.left-menu ul li.data-attr i.icon-place {
    background-image: url('../../../assets/images/page/place-icon.svg');
}
body.fotogallery .content-object.left-menu ul li.data-attr i.icon-calendar {
    background-image: url('../../../assets/images/page/calendar-icon.svg');
}
body.fotogallery .content-object.left-menu ul li.data-attr i.icon-nsfw {
    background-image: url('../../../assets/images/page/nsfw-icon.svg');
}
body.fotogallery .content-object.left-menu ul li.data-attr i.icon-paleta {
    background-image: url('../../../assets/images/page/paleta-icon.svg');
}
body.fotogallery .content-object.left-menu ul li.data-link {
    padding: 5px 10px;
    text-align: center;
    border-top: 1px solid #C6C6C6;
    width: 100%;
}
body.fotogallery .content-object.left-menu ul li.data-link a {
    color: #1487B3;
    text-transform: uppercase;
    font-weight: bold;
}
body.fotogallery .content-object.left-menu ul li.data-link a:hover {
    color: #282828;
}
body.fotogallery .content-object.left-menu {
    background-color: #ffffff;
}


/* fotogallery */


.logo {
    font-family: 'Teko', 'Dosis', sans-serif;
}
nav .container {
    /*padding-left: 0px;
    padding-right: 0px;*/
}
.category-header {
    background: #EAEAEA;
    border-bottom: #1487B3 5px solid;
}
.navs .container {
    background: #EAEAEA;
}
.navbar {
    border: 0px solid transparent;
    border-radius: 0px;
    margin-bottom: 0px;
}
.nav.navbar-nav {
    /* padding: 0px 20px; */
    float: none;
}
.starting-text .container {
    padding-left: 0px;
    padding-right: 0px;
    background-color: #fff;
}
.container-fluid>.navbar-collapse, .container-fluid>.navbar-header, .container>.navbar-collapse, .container>.navbar-header {
    margin-left: 0px;
    margin-right: 0px;
    padding-left: 0px;
    padding-right: 0px;
}
.navbar-default {
    /*background-color: #267D90;*/
    background-color: transparent;
    min-height: 0px;
}
.navbar-nav {
    margin: 0px;
}
.navbar-default .navbar-header {
    /*min-height: 68px;*/
}
.navbar-default .navbar-header .logo {
    font-size: 32px;
    color: #fff;
}
.navbar-nav li {
    padding: 5px 0px;
    text-align: center;
}
.collapse.navbar-collapse {
    /* border-right: 1px solid #54aec2; */
    padding-left: 0px;
    padding-right: 0px;
}
.navbar-default .navbar-nav>li {
    color: #969696;
}
.navbar-default .navbar-nav li a {
    color: #969696;
    padding: 0px 10px;
    border-right: 1px solid #969696;
    text-transform: uppercase;
}
.navbar-default .navbar-nav>.active {
    /*background-color: rgba(5, 207, 252, 0.62);*/
    color: #1487B3;
    background-image: url('../../../assets/images/page/menu-category-bg-icon.svg');
    background-position: bottom;
    background-repeat: no-repeat;
}
.navbar-default .navbar-nav>li>a:hover, .navbar-default .navbar-nav>li>a:focus {
    color: #282828;
}
.navbar-default .navbar-nav>li:last-child a {
    border-right: transparent;
}
.navbar-default .navbar-nav>li:hover, .navbar-default .navbar-nav>li:focus {
    color: #282828;
    background-image: url('../../../assets/images/page/menu-category-bg-icon.svg');
    background-position: bottom;
    background-repeat: no-repeat;
}
.navbar-fixed-top .navbar-collapse {
    /* max-height: 415px; */
}
.container-fluid {
    margin-left: 0px;
    padding-left: 0px;
}
.navbar-default .navbar-nav>.active>a {
    color: #1487B3;
}
.navbar-default .navbar-nav>.active>a:hover, .navbar-default .navbar-nav>.active>a:focus {
    color: #282828;
}
.navbar-default .navbar-nav>.active:hover {}

/*
 * CSS Styles that are needed by jScrollPane for it to operate correctly.
 *
 * Include this stylesheet in your site or copy and paste the styles below into your stylesheet - jScrollPane
 * may not operate correctly without them.
 */

.jspContainer {
    overflow: hidden;
    position: relative;
}
.jspPane {
    position: absolute;
}
.jspVerticalBar {
    position: absolute;
    top: 0;
    right: 0;
    width: 16px;
    height: 100%;
}
.jspHorizontalBar {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 16px;
}
.jspVerticalBar *, .jspHorizontalBar * {
    margin: 0;
    padding: 0;
}
.jspCap {
    display: none;
}
.jspCapRight {
    width: 20px;
}
.jspDragRight {
    background: url('../../../assets/images/page/suwak-right.svg') 0 0 no-repeat;
    height: 100%;
    position: absolute;
    right: 0px;
    top: 0;
    width: 15px;
    z-index: 10;
}
.jspDragLeft {
    background: url('../../../assets/images/page/suwak-left.svg') 0 0 no-repeat;
    height: 100%;
    width: 15px;
}
.jspHorizontalBar .jspCap {
    float: left;
}
.jspTrack {
    /*background: #dde ;*/
    position: relative;
}
.jspDrag {
    /*background: #bbd;*/
    position: relative;
    top: 0;
    left: 0;
    cursor: pointer;
}
.jspHorizontalBar .jspTrack, .jspHorizontalBar .jspDrag {
    float: left;
    height: 100%;
}
body.designgallery .jspHorizontalBar .jspTrack {
    background-image: url('../../../assets/images/page/rule-bg-top-small.svg');
    background-repeat: repeat-x;
    background-position: center;
    background-size: contain;
}
body.fotogallery .jspHorizontalBar .jspTrack {
    background-color: #4F4F4F;
    background-repeat: repeat-x;
    background-position: center;
    background-size: contain;
}

body.obrazygallery .jspHorizontalBar .jspTrack {
    background-color: #2B2C2C;
    background-repeat: repeat-x;
    background-position: center;
    background-size: contain;
}
body.videogallery .jspHorizontalBar .jspTrack {
    background-color: #2B2C2C;
    background-repeat: repeat-x;
    background-position: center;
    background-size: contain;
}



.jspHorizontalBar .jspDrag {
    /*background-image: url('../../../assets/images/page/suwak-bg.svg');*/
    background-repeat: repeat;
    background-size: cover;
    background-color: #EAEAEA;

    min-width: 35px;
}
.jspArrow {
    background: #50506d;
    text-indent: -20000px;
    display: block;
    cursor: pointer;
    padding: 0;
    margin: 0;
}
.jspArrow.jspDisabled {
    cursor: default;
    background: #80808d;
}
.jspVerticalBar .jspArrow {
    height: 16px;
}
.jspHorizontalBar .jspArrow {
    width: 16px;
    float: left;
    height: 100%;
}
.jspVerticalBar .jspArrow:focus {
    outline: none;
}
.jspCorner {
    background: #eeeef4;
    float: left;
    height: 100%;
}

/* Yuk! CSS Hack for IE6 3 pixel bug :( */

* html .jspCorner {
    margin: 0 -3px 0 0;
}
